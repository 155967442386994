
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import OffersView from '@/views/Offers/OffersView.vue';

@Component({
  components: {
    OffersView
  }
})
export default class OffersForMiscView extends PwrVue {
  private scope: number[] = [4];
}
